.skeleton-loaderCarousel .brandsCarousel{
    display: grid;
    overflow: hidden;
    column-gap: 12px;
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: 1fr 0 0 0 0 0;
    overflow: hidden;

    @media (max-width: 1280px) {
        grid-template-columns: repeat(8, 1fr);
    }

    @media (max-width: 992px) {
        grid-template-columns: repeat(6, 1fr);
    }

    @media (max-width: 768px) {
        grid-template-columns: repeat(5, 1fr);
    }


    @media (max-width: 576px) {
        grid-template-columns: repeat(5, 1fr);
    }

    @media (max-width: 390px) {
        grid-template-columns: repeat(4, 1fr);
    }

    .brandItem_component{
        border-radius: 100% !important;
        width: 115px;
        height: 115px;

        @media (max-width: 1280px) {
            width: 100px;
            height: 100px;
        }

        @media (max-width: 992px) {
            width: 100px;
            height: 100px;
        }

        @media (max-width: 576px) {
            width: 70px;
            height: 70px;
        }
    }
}

.brandItem_component {
    border: 1px solid #d9d9d9;
    background: #FFFFFF;
    border-radius: 100%;
    width: 115px;
    height: 115px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;

    @media (max-width: 1280px) {
        width: 100px;
        height: 100px;
    }

    @media (max-width: 992px) {
        width: 100px;
        height: 100px;
    }

    @media (max-width: 576px) {
        width: 70px;
        height: 70px;
    }

    img{
        width: 115px;
        height: 115px;

        @media (max-width: 1280px) {
            width: 100px;
            height: 100px;
        }

        @media (max-width: 992px) {
            width: 100px;
            height: 100px;
        }

        @media (max-width: 576px) {
            width: 70px;
            height: 70px;
        }
    }
}